import { useState, Fragment } from "react";
import { Row, Col, Drawer, Button } from "antd";
import { CSSTransition } from "react-transition-group";
import * as Styled from "./styles";

const Header = () => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {

    const scrollTo = (id) => {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    return (
      <Fragment>
        {/* <Styled.CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Styled.Span>About</Styled.Span>
        </Styled.CustomNavLinkSmall> */}
        <Styled.CustomNavLinkSmall onClick={() => scrollTo("solution")}>
          <Styled.Span>Solution</Styled.Span>
        </Styled.CustomNavLinkSmall>
        <Styled.CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Styled.Span>
            <Button 
              size='large'
              type="primary"
              style={{width: '180px'}}
            >
              Contact Us
            </Button>
          </Styled.Span>
        </Styled.CustomNavLinkSmall>
      </Fragment>
    );
  };

  return (
    <Styled.Header>
      <Styled.Container>
        <Row type="flex" justify="space-between" align="middle" gutter={20}>
          <Styled.LogoContainer to="/" aria-label="homepage">
            <img alt="Tidal Migrations Logo" src="/img/logo-full.png" />
          </Styled.LogoContainer>
          <Styled.NotHidden>
            <MenuItem />
          </Styled.NotHidden>
          <Styled.Burger onClick={showDrawer}>
            <Styled.Outline />
          </Styled.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <Styled.Label onClick={onClose}>
                <Col span={12}>
                  <Styled.Menu>Menu</Styled.Menu>
                </Col>
                <Col span={12}>
                  <Styled.Outline padding="true" />
                </Col>
              </Styled.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </Styled.Container>
    </Styled.Header>
  );
};

export default Header;