import Header from './components/Header/Header';
import ContentBlock from './components/ContentBlock/ContentBlock';
// eslint-disable-next-line
import MiddleBlock from './components/ContentBlock/MiddleBlock';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import IntroContent from './content/intro.json';
import ContactContent from './content/contact.json';
import AboutContent from './content/about.json';
import ReactPlayer from 'react-player'
import { BackTop } from 'antd';
import { ReactComponent as BgShape } from './assets/shape.svg';
import { ReactComponent as BgShape2 } from './assets/shape2.svg';
import { ReactComponent as BgShape3 } from './assets/shape3.svg';
import{ init } from 'emailjs-com';
init("user_e4DOIkFt68liBwByJwhX2");

function App() {
  return (
    <div className="App">
      <BackTop />
      <section className="bg-shape-1" >
        <BgShape />
      </section>
      <Header />
        <div className="main-container">
          <ContentBlock
            type="right"
            firstBlock
            title={IntroContent.title}
            content={IntroContent.text}
            button={IntroContent.button}
            adds={
              <ReactPlayer 
                width='100%'
                style={{margin: "2rem auto"}}
                controls
                url='https://youtu.be/mteh8b19D-I'
              />
            }
            id="intro"
          />
          {/* <MiddleBlock
            title={ProductContent.title}
            content={ProductContent.text}
            id="about"
          /> */}
          <ContentBlock
            type="left"
            title={AboutContent.title}
            content={AboutContent.text}
            adds={
              <img 
                alt="Tidal Migrations Logo" 
                src="/img/cloud_journey.png" 
                style={{width: '100%'}}
              />
            }
            id="solution"
          />
          <section className="bg-shape-2" >
            <BgShape2 />
          </section>
          <ContentBlock
            type="right"
            title={ContactContent.title}
            content={ContactContent.text}
            email={ContactContent.email}
            content2={ContactContent.text2}
            adds={<Contact />}
            id="contact"
          />
          <section className="bg-shape-3" >
            <BgShape3 />
          </section>
        </div>
      <Footer />
    </div>
  );
}

export default App;
