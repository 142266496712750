/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { Form, Input, Button, notification } from "antd";
import { ContactContainer } from './styles';
import * as emailjs from 'emailjs-com'
import ReCAPTCHA from "react-google-recaptcha";

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
  },
};

const Contact = () => {
  const [form] = Form.useForm();
  const [reCapValidated, setReCapValidated] = useState('');
  const recaptchaRef = React.createRef();

  const onFinish = (values) => {
    let templateParams = {
      email: values.email,
      name: values.name,
      subject: values.subject,
      message: values.message,
    }

    emailjs.send('service_d4m5e32', 'template_06n8y2u', templateParams)
      .then(function(response) {
        console.log('Contact SUCCESS!', response.status, response.text);
        notification['success']({
          message: 'Your inquiry has been sent',
          description:
            'Thank you for your inquiry, we will get back to you as soon as we can.',
        });
      }, function(error) {
       console.log('Contact FAILED...', error);
       notification['error']({
        message: 'Oops, something went wrong',
        description:
          'Unfortunatly your inquiry has not been sent successfully, please contact us at value@tidalmigrations.com instead.',
      });
    });

    setTimeout(() => {
      form.resetFields();
      recaptchaRef.reset();
    }, 1500);

  };

  const SITE_KEY = "6LfCnRAbAAAAAOMK5GmdtVFVsGOhOv0F4BKUGGh0";

  const onRecaptchaChange = value => {
    setReCapValidated(value)
  }

  return (
    <ContactContainer>
      <Form 
        form={form}
        layout="vertical"
        name="nest-messages" 
        onFinish={onFinish} 
        validateMessages={validateMessages}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please enter a valid name'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email Address"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please enter a valid email address'
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: 'Please enter a valid subject'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="message" 
          label="Message"
          rules={[
            {
              required: true,
              message: 'Please enter a valid message'
            }
          ]}
        >
          <Input.TextArea rows={3}/>
        </Form.Item>
      </Form>
      <ReCAPTCHA
        theme="light"
        sitekey={SITE_KEY}
        ref={recaptchaRef}
        onChange={onRecaptchaChange}
        style={{marginTop: '1.5rem'}}
      />
      <Button 
        block
        size="large" 
        type="primary" 
        htmlType="submit" 
        disabled={!reCapValidated}
        style={{marginTop: '1.5rem'}}
        onClick={() => form.submit()}
      >
        Submit
      </Button>
    </ContactContainer>
  );
};

export default Contact;