import styled from 'styled-components';

export const Footer = styled.footer`
  background: rgb(250, 250, 252);
  padding: 2.5rem;
`;

export const Title = styled.h4`
  font-size: 16px;
  text-transform: uppercase;
  color: #000;
  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled.a`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: #55c1d3;
  }
`;

export const Target = styled.a`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: #55c1d3;
  }
`;

export const Extra = styled.section`
  background: rgb(249, 250, 252);
  position: relative;
  width: 100%;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  position: relative;
  img {
    width: 120px;
    height: 52px;
  }
`;

export const Select = styled.div`
  line-height: 24px;
  @media only screen and (min-width: 1024px) {
    padding: 0 10%;
  }
`;

export const Para = styled.div`
  color: rgba(2, 7, 62, 0.8);
  max-width: 340px;
  font-size: 14px;
  width: 100%;
`;

export const Large = styled.a`
  font-size: 16px;
  color: rgba(2, 7, 62, 0.8);
  text-align: ${(props) => (props.left ? 'left' : '')};
  padding: ${(props) => (props.left ? '0 10%' : '')};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  &:hover {
    color: #55c1d3;
  }
`;

export const Chat = styled.p`
  color: rgba(2, 7, 62, 0.8);
  max-width: 150px;
  font-size: 16px;
  border-bottom: 1px solid rgba(2, 7, 62, 0.8);
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #55c1d3;
    color: #55c1d3;
  }
`;

export const Empty = styled.div`
  position: relative;
  height: 53px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  span {
    font-size: 1rem;
    margin-left: 1rem
  }
  @media screen and (max-width: 480px) {
    padding: 2rem 0;
    justify-content: flex-start;
    span {
      margin: 0;
      padding-top: 2rem;
      flex-basis: 100%;
    }
  }
`;

export const Hidden = styled.div`
  display: contents;
  width: 100%;
  @media screen and (max-width: 414px) {
    display: none;
  }
`;

export const Language = styled.h4`
  font-size: 16px;
  text-transform: uppercase;
  color: #000;
  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  text-transform: uppercase;
  color: #000;
  display: block;
  margin-bottom: 2rem;
  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
  }
`;

export const LangSelect = styled.select`
  cursor: pointer;
  border: none;
  font-size: 1rem;
  background: rgb(249, 250, 252);
`;