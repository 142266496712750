import styled from 'styled-components';
import { Col } from 'antd';

export const ContentBlock = styled.section`
  position: relative;
  padding: 10rem 0 8rem;
  @media only screen and (max-width: 768px) {
    padding: 4rem 0 2.5rem;
  }
  @media only screen and (max-width: 480px) {
    margin: auto;
    padding: 1rem 0 1rem;
  }
`;

export const Content = styled.p`
  margin: 1.5rem 0 2rem 0;
`;

export const ContentWrapper = styled.div`
  position: relative;
  max-width: 540px;
  a {
    color: #096dd9;
    text-decoration: underline;
  }
  @media only screen and (max-width:  768px) {
    margin: 1rem auto;
  }
`;

export const ServiceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

export const MinTitle = styled.h6`
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.5rem 0;
`;

export const MinPara = styled.p`
  font-size: 0.75rem;
`;

export const ServiceItem = styled(Col)`
  margin: 2rem 0;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    button:last-child {
      margin-top: 1rem;
      flex-basis: 100%;
    }
  }
  /* Ipad Pro */
  /* Portrait */
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    flex-direction: column;
    justify-content: flex-start;
    button:last-child {
      margin-top: 1rem;
      flex-basis: 100%;
    }
  }
`;

export const MiddleBlock = styled.section`
  position: relative;
  padding: 7.5rem 0 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 5.5rem 0 3rem;
  }
`;

export const MiddleContent = styled.p`
  padding: 0.75rem 0 0.75rem;
`;

export const MiddleContentWrapper = styled.div`
  max-width: 570px;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
