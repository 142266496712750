/* eslint-disable jsx-a11y/alt-text */
import { Fragment } from "react";
import { Row, Col } from "antd";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import * as Styled from "./styles";

const Footer = () => {

  return (
    <Fragment>
      <Fade bottom>
        <Styled.Footer>
          <div className="main-container">
            <Row>
              <Col md={8} sm={12} xs={24}>
                <Styled.Title>What we Offer</Styled.Title>
                <Styled.Large href="https://cloudcalculator.com" >
                  Cloud Calculator
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/cloud-application-readiness-assessment/" >
                  Cloud Application Readiness Assessment
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/solutions/cloud-migration/">
                  Cloud Migration Solution
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/training/cloud-migration-enablement-program/">
                  Training
                </Styled.Large>
              </Col>
              <Col md={8} sm={12} xs={24}>
                <Styled.Title>Company</Styled.Title>
                <Styled.Large href="https://tidalmigrations.com/why-tidal/">
                  Why Tidal?
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/security/">
                  Security
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/why-partner-with-tidal/">
                  Partner with Tidal
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/blog/">
                  Blog
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/news/">
                  News
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/webinars/">
                  Webinar
                </Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/legals/">
                  Legals & Privacy
                </Styled.Large>
              </Col>
              <Col md={8} sm={12} xs={24}>
                <Styled.Language>Get Help</Styled.Language>
                <Styled.Large href="https://tidalmigrations.com/faq/">FAQ</Styled.Large>
                <Styled.Large href="https://tidalmigrations.com/support/">Support</Styled.Large>
                <Styled.Large href="https://guides.tidalmg.com/index.html">API Docs</Styled.Large>
                <Styled.Large href="mailto:value@tidalmigrations.com">
                  <Styled.Chat>Contact Us by Email</Styled.Chat>
                </Styled.Large>
              </Col>
            </Row>
          </div>
        </Styled.Footer>
        <Styled.Extra>
          <div className="main-container" style={{borderTop: "1px solid #CDD1D4"}}>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >
              <Styled.NavLink href="/">
                <Styled.LogoContainer>
                  <img
                    src="/img/logo.png"
                    aria-label="Tidal Migrations Logo"
                  />
                </Styled.LogoContainer>
              </Styled.NavLink>
              <Styled.FooterContainer>
                <a
                  href="https://www.linkedin.com/company/tidal-migrations"
                  style={{ color: '#0e76a8' }}
                >
                  <FontAwesomeIcon icon={faLinkedin} style={{width: '30px', height: '30px'}} />
                </a>
                <a
                  href="https://twitter.com/tidalmigrations"
                  style={{ color: '#1da1f2' }}
                >
                  <FontAwesomeIcon icon={faTwitter} style={{width: '30px', height: '30px', marginLeft: '2rem'}} />
                </a>
                <span>&nbsp; © &nbsp;2021 Tidal Migrations</span>
              </Styled.FooterContainer>
            </Row>
          </div>
        </Styled.Extra>
      </Fade>
    </Fragment>
  );
};

export default Footer;
