import styled from 'styled-components';

export const ContactContainer = styled.div`
  padding: 5rem 0;
  position: relative;
  max-width: 540px;
  margin: auto;
  .ant-form-item {
    margin-bottom: 1rem;
  }
`;