import { Row, Col, Button } from "antd";
import Slide from "react-reveal/Slide";
import * as Styled from "./styles";
import * as Fathom from 'fathom-client';

const ContentBlock = ({ adds, title, content, email, content2, section, id, type, button }) => {

  return (
    <Styled.ContentBlock>
      <Row type="flex" justify="space-between" align="middle" id={id}>
        <Col lg={11} md={11} sm={24} xs={24}>
          <Slide left>
            {type==='left' ?
              adds
              :
              <Styled.ContentWrapper>
                <h6>{title}</h6>
                <Styled.Content>
                  {content}
                  {email && content2 &&
                    <>
                      <a href="mailto:value@tidalmigrations.com">
                        {email}
                      </a>
                      <br/><br/>
                      <p>{content2}</p>
                    </>
                  }
                </Styled.Content>
              <Styled.ButtonWrapper>
                {button &&
                  typeof button === "object" &&
                  button.map((item, id) => {
                    return (
                      <Button
                        key={id}
                        type={item.type}
                        size="large"
                        width="true"
                        onClick={(e) => {
                          e.preventDefault();
                          if(item.fathom_goal){
                            Fathom.trackGoal(item.fathom_goal, item.goal_value);
                          }
                          window.open(item.link, '_blank');
                        }}
                      >
                        {item.title}
                      </Button>
                    );
                  })}
              </Styled.ButtonWrapper>
            </Styled.ContentWrapper>
            }
          </Slide>
        </Col>
        <Col lg={11} md={11} sm={24} xs={24}>
          <Slide right>
            {type==='right' ?
              adds
              :
              <Styled.ContentWrapper>
                <h6>{title}</h6>
                <Styled.Content>{content}</Styled.Content>
                <Styled.ServiceWrapper>
                  <Row type="flex" justify="space-between">
                    {section &&
                      typeof section === "object" &&
                      section.map((item, id) => {
                        return (
                          <Col key={id} span={11}>
                            <img alt="" src={item.icon} width="60px" height="60px" />
                            <Styled.MinTitle>{item.title}</Styled.MinTitle>
                            <Styled.MinPara>{item.content}</Styled.MinPara>
                          </Col>
                        );
                      })}
                  </Row>
                </Styled.ServiceWrapper>
              </Styled.ContentWrapper>
            }
          </Slide>
        </Col>
      </Row>
    </Styled.ContentBlock>
  );
};

export default ContentBlock;
